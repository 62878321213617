import React from 'react'
import "./mainFooter.scss"

export default function MainFooter() {
  return (
    <div className='mainfooter'>
        <div className="greenscreamlogo">
            
            <span>by greenscream</span>
            <img src="/images/logo.png"alt="" />
        </div>
        
    </div>
  )
}
